.english-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.english-header {
  text-align: center;
  margin-bottom: 3rem;
}

.english-header h1 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 1rem;
}

.english-header p {
  font-size: 1.2rem;
  color: #666;
}

.units-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.unit-card {
  background: linear-gradient(135deg, #e8eaf6 0%, #c5cae9 100%);
  border: 2px solid #7986cb;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
}

.unit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.unit-card h2 {
  font-size: 1.5rem;
  color: #1a237e;
  margin-bottom: 0.5rem;
}

.unit-card p {
  color: #283593;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.unit-detail {
  color: #666;
  font-size: 1rem;
}

.back-button {
  display: block;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #1a237e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #283593;
}

/* Responsive tasarım */
@media (max-width: 768px) {
  .english-container {
    padding: 1rem;
  }

  .units-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .unit-card {
    padding: 1.5rem;
  }

  .english-header h1 {
    font-size: 2rem;
  }

  .english-header p {
    font-size: 1rem;
  }
} 