.quiz-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quiz-header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: linear-gradient(135deg, #e8eaf6 0%, #c5cae9 100%);
  border-radius: 8px;
}

.quiz-header h1 {
  color: #1a237e;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.quiz-description {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 600px;
}

.question-section {
  margin-bottom: 2rem;
}

.question-count {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #666;
}

.question-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1.5rem;
}

.answer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.answer-button {
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-align: left;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer-button:hover:not(:disabled) {
  background-color: #f5f5f5;
  border-color: #2196f3;
}

.answer-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.correct-indicator, .incorrect-indicator {
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 10px;
}

.correct-indicator {
  color: #2e7d32;
}

.incorrect-indicator {
  color: #c62828;
}

.answer-button.correct {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: #2e7d32;
  animation: correctAnswer 0.3s ease;
}

.answer-button.incorrect {
  background-color: #ffebee;
  border-color: #f44336;
  color: #c62828;
  animation: incorrectAnswer 0.3s ease;
}

@keyframes correctAnswer {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes incorrectAnswer {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.score-section {
  text-align: center;
  padding: 2rem;
}

.score-section h2 {
  color: #333;
  margin-bottom: 1rem;
}

.score-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #666;
}

.performance-feedback {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.perfect-score {
  color: #2e7d32;
  font-weight: 600;
}

.good-score {
  color: #1976d2;
  font-weight: 600;
}

.improve-score {
  color: #f57c00;
  font-weight: 600;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.button-group button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.button-group button:hover {
  background-color: #1976d2;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error button {
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.error button:hover {
  background-color: #1976d2;
}

.answer-review {
  margin-top: 2rem;
  text-align: left;
}

.answer-review h3 {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.review-item {
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border-left: 4px solid #e0e0e0;
}

.review-item.correct {
  border-left-color: #4caf50;
  background-color: #f1f8e9;
}

.review-item.incorrect {
  border-left-color: #f44336;
  background-color: #fef2f2;
}

.review-question {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #333;
}

.review-answer {
  margin-bottom: 0.25rem;
  color: #666;
}

.correct-answer {
  color: #666;
}

.correct-text {
  color: #4caf50;
  font-weight: 500;
}

.incorrect-text {
  color: #f44336;
  font-weight: 500;
}

.learning-help {
  margin-top: 2rem;
  text-align: center;
}

.tip-button {
  padding: 0.5rem 1rem;
  border: 2px solid #1a237e;
  border-radius: 5px;
  background-color: transparent;
  color: #1a237e;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.tip-button:hover {
  background-color: #1a237e;
  color: white;
}

.tip-content {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Responsive tasarım */
@media (max-width: 768px) {
  .quiz-container {
    margin: 1rem;
    padding: 15px;
  }

  .quiz-header h1 {
    font-size: 1.5rem;
  }

  .quiz-description {
    font-size: 1rem;
  }

  .question-text {
    font-size: 1.2rem;
  }

  .answer-button {
    padding: 0.8rem;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
  }
} 