.App {
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  color: #1976d2;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
} 