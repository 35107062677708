.home-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.home-header {
  text-align: center;
  margin-bottom: 3rem;
}

.home-header h1 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 1rem;
}

.home-header p {
  font-size: 1.2rem;
  color: #666;
}

.platform-description {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(135deg, #e8eaf6 0%, #c5cae9 100%);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platform-description h2 {
  color: #1a237e;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.platform-description p {
  line-height: 1.6;
  color: #333;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.category-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 300px;
  text-align: center;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.category-card h2 {
  font-size: 1.5rem;
  color: #1a237e;
  margin-bottom: 1rem;
}

.category-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.category-info {
  margin-top: 1.5rem;
  text-align: left;
  width: 100%;
}

.category-info p {
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.9rem;
}

/* Kategori kartları için özel renkler */
.category-card:nth-child(1) {
  background: linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%);
  border: 2px solid #64b5f6;
}

.category-card:nth-child(2) {
  background: linear-gradient(135deg, #f3e5f5 0%, #e1bee7 100%);
  border: 2px solid #ba68c8;
}

.category-card:nth-child(3) {
  background: linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%);
  border: 2px solid #81c784;
}

.category-card:nth-child(4) {
  background: linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%);
  border: 2px solid #ffb74d;
}

.category-card:nth-child(5) {
  background: linear-gradient(135deg, #e8eaf6 0%, #c5cae9 100%);
  border: 2px solid #7986cb;
}

.learning-tips {
  margin-top: 4rem;
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.learning-tips h2 {
  text-align: center;
  color: #1a237e;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.tips-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.tip-card {
  padding: 1.5rem;
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  border-radius: 8px;
  text-align: center;
}

.tip-card h3 {
  color: #1a237e;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.tip-card p {
  color: #333;
  line-height: 1.5;
}

/* Responsive tasarım */
@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }

  .category-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .category-card {
    padding: 1.5rem;
  }

  .home-header h1 {
    font-size: 2rem;
  }

  .home-header p {
    font-size: 1rem;
  }

  .platform-description {
    padding: 1.5rem;
  }

  .platform-description h2 {
    font-size: 1.5rem;
  }

  .tips-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
} 